.waya-official .waya__spinner-container {
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(0, 175, 170, 0.0001) 0deg,
    #FF6700 359.96deg,
    rgba(0, 175, 170, 0.0001) 360deg
  );
  width: 102px;
  height: 102px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: waya__spinner__rotating__container;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.waya-official .waya__spinner-content {
  background-color: #fff;
  width: calc(102px - 1rem);
  height: calc(102px - 1rem);
  border-radius: 50%;
}

@keyframes waya__spinner__rotating__container {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
