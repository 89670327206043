.referrals__container {
  --light-orange: #e7472c29;
  --orange: #ff6634;
  --dark-orange: #ff4b01;
  --blue: #0086e8;
  --light-lime: #eafff3;
  --lime: #aff9ce;
  --dark-lime: #27ae60;
  --shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  --bg: #fbfbfb;
  --extra-lighter-dark: #e8e7e7;
  --extra-light-dark: #978c8a;
  --lighter-dark: #978c8a;
  --light-dark: #6a6a6a;
  --extra-heavy-dark: #1c1c1c;
  --extra-heavier-dark: #000000;
  --light: #fff;
  --referrals-bg: #f5f5f5;

  background-color: var(--referrals-bg);
  font-family: "Inter", sans-serif;
  color: #333333;
}

.hidden {
  display: none;
}

.referrals__container * {
  box-sizing: border-box;
}

.referrals__container p {
  margin: 0;
}

.referrals__container button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.referrals__container > * + * {
  margin-top: 2.5rem;
}

.referrals__top-actions {
  display: flex;
  justify-content: space-between;
}

.referrals__top-actions > * {
  flex-shrink: 0;
  align-self: flex-start;
}

.referrals__search-container {
  position: relative;
  display: block;
  border: 1px solid var(--orange);
  border-radius: 0.375rem;
  overflow: hidden;
}

.referrals__search-icon {
  position: absolute;
  top: 50%;
  transform: translateX(50%) translateY(-40%);
}

.referrals__search-input {
  display: block;
  padding: 0.8rem;
  padding-left: 3rem;
  outline: none;
  border: none;
}

.referrals__search-input::placeholder {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--extra-light-dark);
}

.referrals__top-actions__btn-container {
  display: flex;
}

.referrals__top-actions__btn-container > * {
  align-self: flex-start;
}

.referrals__top-actions__btn-container > * + * {
  margin-left: 0.5rem;
}

.referrals__container .referrals__top-actions__btn {
  --top-actions-btn-border-color: var(currentColor);
  padding: 0.6rem;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--top-actions-btn-border-color);
  border-radius: 0.25rem;
}

.referrals__container .assign-code-btn {
  background-color: var(--dark-orange);
  --top-actions-btn-border-color: var(--dark-orange);
}

.referrals__container .bulk-bonus-btn {
  background-color: var(--blue);
  --top-actions-btn-border-color: var(--blue);
}

.referrals__container .export-referral-data-btn {
  background-color: var(--dark-lime);
  --top-actions-btn-border-color: var(--dark-lime);
}

.referrals__container .referral-setting-btn {
  color: var(--lighter-dark);
  background-color: var(--extra-lighter-dark);
  background-color: var(--extra-lighter-dark);
  --top-actions-btn-border-color: #d0cfcf;
}

.referrals__container .referral-setting-btn-text {
  margin-left: 0.4rem;
}

.referral-item-amounts-container {
  display: flex;
  column-gap: 2rem;
}

.referral-item-amount {
  flex: 1 0 auto;
  padding: 1.2rem;
  box-shadow: var(--shadow);
  border-radius: 0.25rem;
  background-color: var(--light);
}

@supports not (column-gap: 2rem) {
  .referral-item-amount:nth-child(odd) {
    margin-right: 1rem;
  }

  .referral-item-amount:nth-child(even) {
    margin-left: 1rem;
  }
}

.referral-item-amount__content:not(:first-child) {
  margin-top: 2rem;
}

.referral-item-amount__text {
  color: #1c1c1c;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.referral-item-amount__amount {
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
  color: #1c1c1c;
}

.referral__main-content > * + * {
  margin-top: 1.5rem;
}

.referral__date-container {
}

.referral__date-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  color: var(--extra-light-dark);
  letter-spacing: 0.08rem;
}

.referral__date {
  background-color: var(--light);
  border-radius: 0.25rem;
  padding: 0.5rem 0.8rem;
  outline: none;
  border: none;
  box-shadow: var(--shadow);
  font-size: 1rem;
  color: var(--extra-light-dark);
}

.referral__table-container {
  overflow: auto;
  border-radius: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.referral__table-container::-webkit-scrollbar {
  display: none;
}

.referral__table-container > div {
  display: inline-block;
  min-width: 100%;
}

.referral__table {
  background-color: var(--light);
  min-width: 100%;
  text-align: center;
}

.referral__table__head {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  color: var(--orange);
  background-color: var(--light-orange);
}

.referral__table__head > th {
  padding: 1.2rem;
}

.word-wrap {
  white-space: wrap;
}

.referral__table__row {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.referral__table__row:not(:last-child) td {
  border-bottom: 1px solid var(--extra-lighter-dark);
}

.referral__table__row td {
  padding: 1rem;
}

.referral__table__row .referral__table__send-btn {
  border: 1px solid #aff9ce;
  background-color: #eafff3;
  color: #27ae60;
  border-radius: 0.25rem;
  padding: 0.5rem 0.8rem;
}

.referral__table__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange);
  background-color: var(--light-orange);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  padding: 1rem;
  width: inherit;
}

.referrals__container .referral__table__footer > * + * {
  margin-left: 4rem;
}

.referral__table__rows-per-page-container {
  display: flex;
  position: relative;
  align-items: center;
}

.referral__table__rows-per-page-container > * + * {
  margin-left: 0.5rem;
}

.referral__table__rows-per-page-container ul.rows-per-page-dropdown {
  position: absolute;
  bottom: 0;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  background-color: var(--light);
}

.referral__table__rows-per-page-container .rows-per-page-dropitem {
  margin: 0;
}

.referral__table__rows-per-page-container .rows-per-page-dropitem button {
  padding: 0.6rem 1.6rem 0.6rem 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: var(--extra-heavy-dark);
}

.referral__table__rows-per-page-container .rows-per-page-dropitem button:hover,
.referral__table__rows-per-page-container .rows-per-page-dropitem button:focus {
  background-color: var(--referrals-bg);
}

.referral__table__rows-per-page-container .rows-per-page-dropitem:not(:last-child) {
  border-bottom: 1px solid var(--extra-lighter-dark);
}

.referral__table__rows-per-page-drop-icon {
  transform: translateY(25%);
}

.referral-page-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-page-nav-container > * + * {
  margin-left: 3rem;
}

.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  z-index: 200;
}
