.waya-transfer-modal__wallet-section {
}

.waya-transfer-modal__wallet-section__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.waya-transfer-modal__wallet-section__title {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
}

.waya-transfer-modal__wallet-section__top .waya-transfer-modal__wallet-section__delete-wallet {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  background-color: #ff0101;
  border-radius: 3px;
  padding: 0.4rem 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waya-transfer-modal__add-another-request {
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
}

.waya-transfer-modal__add-another-request p.waya-transfer-modal__add-another-request-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-left: 0.5rem;
}
