.bulk-bonus-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-bonus-modal .bulk-bonus-modal-main-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  width: min(70vw, 542px);
}

.bulk-bonus-modal .bulk-bonus-modal-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-bonus-modal .bulk-bonus-modal-title-container > * {
  display: block;
}

.bulk-bonus-modal .bulk-bonus-modal-title-container h3 {
  margin: 0 auto;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}

.bulk-bonus-modal .bulk-bonus-modal-description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #666666;
  margin-top: 1.2rem;
}

.bulk-bonus-modal .bulk-bonus-input-group {
  margin-top: 4rem;
  padding: 0 2rem;
}

.bulk-bonus-modal .bulk-bonus-input-group > * {
  display: block;
}

.bulk-bonus-modal .bulk-bonus-input-group label {
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #333333;
  margin-bottom: 6px;
}
.bulk-bonus-modal .bulk-bonus-file-input-container {
  overflow: hidden;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: flex;
}

.bulk-bonus-modal .bulk-bonus-file-input-container p {
  flex-grow: 1;
}

.bulk-bonus-modal .bulk-bonus-file-input-container {
  height: 45px;
}

.bulk-bonus-modal .bulk-bonus-file-input-container button {
  background: #ececec;
  border: none;
  outline: none;
  color: #333333;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: block;
  padding: 0 1rem;
  border-left: 1px solid #d3d3d3;
}

.bulk-bonus-modal .bulk-bonus-file-input-container input {
  display: none;
}

.bulk-bonus-modal .bulk-bonus-save-btn-container button {
  display: block;
  margin: 0 auto;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  background: #ff4b01;
  border-radius: 0.25rem;
  padding: 0.6rem 1.2rem;
  margin-top: 3rem;
}
