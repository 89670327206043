.waya-official {
  --light-orange: #e7472c29;
  --orange: #ff6634;
  --dark-orange: #ff4b01;
  --blue: #0086e8;
  --light-lime: #eafff3;
  --lime: #aff9ce;
  --dark-lime: #27ae60;
  --shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  --bg: #fbfbfb;
  --extra-lighter-dark: #e8e7e7;
  --extra-light-dark: #978c8a;
  --lighter-dark: #978c8a;
  --light-dark: #6a6a6a;
  --extra-heavy-dark: #1c1c1c;
  --extra-heavier-dark: #000000;
  --light: #fff;
  --referrals-bg: #f5f5f5;
}

.waya-official__container {
  background-color: #f5f5f5;
  font-family: "Inter", sans-serif;
  color: #333333;
  padding: 0.5rem 1rem;
}

.waya-official hidden {
  display: none;
}

.waya-official * {
  box-sizing: border-box;
}

.waya-official p,
h5 {
  margin: 0;
}

.waya-official ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.waya-official button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.waya-official input {
  border: none;
  outline: none;
}

.waya-official .admin-details-container {
  background: #fff7f4;
  border: 1px solid #ff6634;
  border-radius: 12px;
  display: flex;
  padding: 1rem;
}

.waya-official .admin-details-img-container {
  width: 102px;
  height: 102px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.8rem;
}

.waya-official .admin-details-img-container > img {
  width: 100%;
}

.waya-official .admin-details-name-phone-online-container {
}

.waya-official .admin-details-name-phone-container > p {
  display: inline-block;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
}

.waya-official .admin-details-name-phone-container > * + * {
  margin-left: 0.25rem;
}

.waya-official .admin-details-name {
  font-weight: 600;
}

.waya-official .admin-details-phone {
  font-weight: 500;
}

.waya-official .admin-details-online {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #16b079;
}

.waya-official .admin-other-details-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.waya-official .admin-other-details-container-content {
  display: flex;
}

.waya-official .admin-other-details-container-content > * + * {
  margin-left: 4rem;
}

.waya-official .admin-other-detail-container {
  text-align: left;
}

.waya-official .admin-other-detail-title {
  font-weight: 400;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #e7472c;
  font-size: 12px;
  line-height: 14px;
}

.waya-official .admin-other-detail-content {
  font-weight: 600;
  color: #333333;
}

.waya-official .admin-other-detail-content.waya-super-admin {
  color: #e7472c;
}

.waya-official .waya-account-settings-container {
  position: relative;
}

.waya-official .waya-account-settings {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  background-color: #141414;
  border-radius: 4px;
  padding: 6px 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  align-self: flex-end;
}

.waya-official .waya-account-settings-dropdown {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  right: -1px;
  background-color: white;
  box-shadow: var(--shadow);
  border-radius: 4px;
}

.waya-official .waya-account-settings-dropitem button {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem 1.2rem;
}

.waya-official .waya-account-settings-dropitem button:hover,
.waya-official .waya-account-settings-dropitem button:focus {
  background-color: var(--bg);
}

.waya-official .waya-account-settings-dropitem button p {
  white-space: nowrap;
  margin-left: 1rem;
}

.waya-official .sub-section {
  margin-top: 45px;
}

.waya-official .sub-section-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #333333;
  text-align: left;
  margin-bottom: 8px;
}

.waya-official .account-action-btns-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.waya-official .switch-text-and-btn-container {
  background: #fffaf9;
  border: 1px solid #ffe3d8;
  border-radius: 4px;
  padding: 0.1rem 0.6rem;
}

.waya-official .switch-text-and-btn-container > * {
  display: inline-block;
}

.waya-official .switch-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
  margin-right: 0.8rem;
}

.waya-official .switch-btn-container {
  position: relative;
  background: #ff4b01;
  border-radius: 30px;
  width: 30px;
  height: 14px;
}

.waya-official .switch-btn {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.waya-official .switch-btn.switch-btn-on {
  left: -1px;
}

.waya-official .switch-btn.switch-btn-off {
  right: -1px;
}

.waya-official .create-account-btn {
  background-color: #ff4b01;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
}

.waya-official .create-account-btn-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #ffffff;
  margin-left: 0.5rem;
}

.waya-official .account-type-cards-container {
  overflow-x: auto;
  display: flex;
  padding-bottom: 0.5rem;
}

.waya-official .account-type-cards-container > * + * {
  margin-left: 0.5rem;
}

.waya-official .account-type-card-container {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 0.5rem;
  min-width: 25%;
}

.waya-official .account-type-card-container > * + * {
  margin-top: 1rem;
}

.waya-official .account-type-card-container:last-child {
  margin-right: 3rem;
}

.waya-official .account-type-card__top {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.waya-official .account-type-card__top > svg {
  display: inline-block;
  margin-right: 4px;
}

.waya-official .account-type-card__type {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a8a8a8;
}

.waya-official .account-type-card__more-btn-container {
  position: relative;
  margin-left: auto;
}

.waya-official .account-type-card__more-btn {
}

.waya-official .account-type-card__more-dropdown {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  top: 100%;
  right: 0;
  margin-top: 2px;
}

.waya-official .account-type-card__more-dropitem button {
  padding-left: 0.5rem;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #333333;
}

.waya-official .account-type-card__more-dropitem.delete_item button {
  color: #ff0000;
}

.waya-official .account-type-card__amount {
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: #333333;
  text-align: left;
}

.waya-official .account-type-card__detail-container {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.waya-official .account-type-card__detail-container > * + * {
  margin-left: 1rem;
}

.waya-official .account-type-card__detail {
}

.waya-official .account-type-card__title {
  font-size: 9px;
  line-height: 11px;
  color: #a8a8a8;
  margin-bottom: 0.15rem;
}

.waya-official .account-type-card__content {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
}

.waya-official .money-totals-container {
  display: flex;
  overflow-y: auto;
  margin-top: 1rem;
  justify-content: center;
}

.waya-official .money-totals-container > * + * {
  margin-left: 1rem;
}

.waya-official .money-total {
  display: flex;
  color: #333333;
  background: #ffffff;
  border-radius: 4px;
  padding: 0.5rem;
  min-width: 30%;
  justify-content: space-between;
  flex-grow: 1;
  padding: 1rem;
}

.waya-official .money-total__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.waya-official .money-total__amount {
  margin-left: 4rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.1px;
}

/*  ===================== Transactions Table ============ */

/* ============= Pre-table ============ */

.waya-official .recent-transactions__actions-container {
  display: flex;
  justify-content: space-between;
}

.waya-official .recent-transactions__search-action {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  overflow: hidden;
  height: fit-content;
}

.waya-official .recent-transactions__search-action__icon-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 0.6rem;
  display: flex;
  justify-content: center;
}

.waya-official .recent-transactions__search-action__input {
  display: block;
  width: 100%;
  padding: 0.6rem;
  padding-left: 2.5rem;
}

.waya-official .recent-transactions__action-btns-container {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
}

.waya-official .recent-transactions__action-btns-container > * + * {
  margin-left: 1rem;
}

.waya-official .recent-transactions__export-btn,
.waya-official .recent-transactions__perform-btn {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

.waya-official .recent-transactions__export-btn {
  color: #27ae60;
  border-color: #27ae60;
  display: flex;
  align-items: center;
  padding: 0.4rem;
}

.waya-official .recent-transactions__export-btn > * + * {
  margin-left: 0.3rem;
}

.waya-official .recent-transactions__perform-btn {
  color: #ff4b01;
  border-color: #ff4b01;
  padding: 0.4rem 1.5rem;
}

.waya-official .recent-transactions__filter-actions-container {
  margin-top: 1rem;
  display: flex;
}

.waya-official .recent-transactions__filter-actions-container > * + * {
  margin-left: 2rem;
}

.waya-official .recent-transactions__filter-action {
}

.waya-official .recent-transactions__filter-action__input-label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
  color: #978c8a;
  letter-spacing: 0.02rem;
}

.waya-official .recent-transactions__filter-action__input-container {
  position: relative;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;
  background-color: #fff;
}

.waya-official .recent-transactions__filter-action__input-container input {
  display: block;
  width: 150px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #9e9e9e;
  padding: 0.5rem;
  padding-right: 2rem;
}

.waya-official .recent-transactions__filter-action__status-icon-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.waya-official .waya-official__setting-transaction-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 200;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background-color: #fff;
}

.waya-official .recent-transactions__filter-actions-container {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.waya-official
  .recent-transactions__filter-action__input-container
  .recent-transactions__filter-action__date {
  padding-right: 0.5rem;
}

.waya-official .recent-transactions__filter-action__date {
  appearance: none;
}

/* ==================== Table ====================== */

.waya-official__table-container {
  overflow: auto;
  border-radius: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.waya-official__table-container::-webkit-scrollbar {
  display: none;
}

.waya-official__table-container > div {
  display: inline-block;
  min-width: 100%;
}

.waya-official__table {
  background-color: var(--light);
  min-width: 100%;
  text-align: center;
  text-align: left;
}

.waya-official__table .waya-table-text-right {
  text-align: right;
}

.waya-official__table .waya-table-text-center {
  text-align: center;
}

.waya-official__table__head {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  color: var(--orange);
  background-color: var(--light-orange);
}

.waya-official__table__head > th {
  padding: 1.2rem;
}

.word-wrap {
  white-space: wrap;
}

.waya-official__table__row {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

.waya-official__table__row:not(:last-child) td {
  border-bottom: 1px solid var(--extra-lighter-dark);
}

.waya-official__table__row td {
  padding: 1rem;
}

.waya-table__id-container {
  display: flex;
  align-items: center;
}

.waya-table__id-container .waya-table__id-text {
  margin-right: 0.3rem;
}

.waya-official .waya-table__type {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}

.waya-official .waya-table__credit-type {
  color: #27ae60;
}

.waya-official .waya-table__debit-type {
  color: #FF6700;
}

.waya-official .waya-table__status {
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
}

.waya-official .waya-table__status-reversed {
  color: #0086e8;
}

.waya-official .waya-table__status-successful {
  color: #27ae60;
}

.waya-official .waya-table__status-pending {
  color: #ccc;
}

.waya-official__table__row .waya-official__table__send-btn {
  border: 1px solid #aff9ce;
  background-color: #eafff3;
  color: #27ae60;
  border-radius: 0.25rem;
  padding: 0.5rem 0.8rem;
}
.waya-official .waya-official__table__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange);
  background-color: var(--light-orange);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  padding: 1rem;
  width: inherit;
}

.waya-official .waya-official__table__footer > * + * {
  margin-left: 4rem;
}

.waya-official .waya-official__table__rows-per-page-container {
  display: flex;
  position: relative;
  align-items: center;
}

.waya-official .waya-official__table__rows-per-page-container > * + * {
  margin-left: 0.5rem;
}

.waya-official .waya-official__table__rows-per-page-drop-icon {
  transform: translateY(25%);
}

.waya-official
  .waya-official__table__rows-per-page-container
  ul.rows-per-page-dropdown {
  position: absolute;
  bottom: 0;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  background-color: var(--light);
}

.waya-official
  .waya-official__table__rows-per-page-container
  .rows-per-page-dropitem {
  margin: 0;
}

.waya-official
  .waya-official__table__rows-per-page-container
  .rows-per-page-dropitem
  button {
  padding: 0.6rem 1.6rem 0.6rem 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: var(--extra-heavy-dark);
}

.waya-official
  .waya-official__table__rows-per-page-container
  .rows-per-page-dropitem
  button:hover,
.waya-official
  .waya-official__table__rows-per-page-container
  .rows-per-page-dropitem
  button:focus {
  background-color: var(--referrals-bg);
}

.waya-official
  .waya-official__table__rows-per-page-container
  .rows-per-page-dropitem:not(:last-child) {
  border-bottom: 1px solid var(--extra-lighter-dark);
}

.waya-official .waya-table__page-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.waya-official .waya-table__page-nav-container > * + * {
  margin-left: 3rem;
}
