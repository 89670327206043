.successful-status-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successful-status-modal .successful-status-main-content {
  background-color: #ffffff;
  border-radius: 8px;
  width: min(70vw, 596px);
  overflow: hidden;
}

.successful-status-modal .success-main-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 2rem;
}

.successful-status-modal .success-main-body > * + * {
  margin-top: 1rem;
}

.successful-status-modal .success-icon-content {
  background-color: #2196531a;
  padding: 1.6rem;
  border-radius: 50%;
  width: fit-content;
}

.successful-status-modal .success-icon-content p {
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successful-status-modal .successful-title {
  font-size: 24px;
  line-height: 29px;
  color: #333333;
}

.successful-status-modal .successful-description {
  font-size: 16px;
  line-height: 19px;
  color: #828282;
}

.successful-status-modal .success-btn-container {
  font-size: 16px;
  line-height: 19px;
  background-color: #f2f2f2;
  text-align: center;
  padding: 1rem 0;
  margin-top: 1.8rem;
}

.successful-status-modal .success-btn-container button {
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 22px;
  color: #FF6700;
}
