.waya-official .rename-account-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waya-official .rename-account-modal h3 {
  margin: 0;
}

.waya-official .rename-account-modal .rename-account-modal__main-content {
  background-color: #ffffff;
  border-radius: 8px;
  width: min(80vw, 606px);
  /* overflow: hidden; */
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  max-height: 85vh;
}

.waya-official .rename-account-modal .rename-account-modal__main-content.with-overflow-auto {
  display: flex;
  flex-direction: column;
}

.waya-official
  .rename-account-modal
  .rename-account-modal__main-content.with-overflow-auto
  .rename-account-modal__body {
  flex: 1 1 0;
  height: 100%;
  overflow: auto;
}

.waya-official .rename-account-modal .rename-account-modal__main-content.wider-main-content {
  width: min(90vw, 700px);
}

.waya-official .rename-account-modal .rename-account-modal__body {
  overflow: auto;
}

.waya-official .rename-account-modal .rename-account-modal__title-container {
  color: #333333;
  margin-bottom: 2rem;
}

.waya-official .rename-account-modal .rename-account-modal__title-container > * {
  display: inline-block;
}

.waya-official .rename-account-modal .rename-account-modal__title-container > * + * {
  margin-left: 1rem;
}

.waya-official .rename-account-modal .rename-account-modal__close-btn {
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
}

.waya-official .rename-account-modal .rename-account-modal__title-container {
  color: #333333;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.waya-official .rename-account-modal .rename-account-modal__title-container > * {
  display: inline-block;
}

.waya-official .rename-account-modal .rename-account-modal__title-container > * + * {
  margin-left: 1rem;
}

.waya-official .rename-account-modal .rename-account-modal__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #666666;
}

.rename___account__form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
  padding-left: 8%;
  padding-right: 8%;
}

.rename-account-modal__main-content.rename-account-with-col-form .rename___account__form,
.rename-account-modal__main-content .rename___account__form.rename-account-with-col-form {
  padding-left: 4%;
  padding-right: 4%;
  border: 1px solid #cacaca;
  border-radius: 5px;
  padding-bottom: 2rem;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rename-account-modal__main-content .rename___account__form.rename-account-with-col-form {
  margin-top: 0.25rem;
}

.rename-account-modal__main-content.rename-account-with-col-form .rename___account__form > *,
.rename-account-modal__main-content .rename___account__form.rename-account-with-col-form > * {
  flex-grow: 1;
  width: 40% !important;
}

.waya-official .rename-account-modal .rename-account-modal-input-group:not(:first-child),
.waya-official .rename-account-modal .rename-account-modal-radio-input-group-container {
  margin-top: 2rem;
}

.rename-account-modal__main-content.rename-account-with-col-form
  .rename___account__form
  .rename-account-modal-input-group,
.rename-account-modal__main-content
  .rename___account__form.rename-account-with-col-form
  .rename-account-modal-input-group {
  margin-top: 2rem;
}

.rename-account-modal__main-content.rename-account-with-col-form
  .rename___account__form
  .rename-account-modal-input-group:nth-child(even),
.rename-account-modal__main-content
  .rename___account__form.rename-account-with-col-form
  .rename-account-modal-input-group:nth-child(even) {
  margin-left: 2rem;
}

.waya-official .rename-account-modal .rename-account-modal-input-group,
.rename-account-modal__full-input-group {
  width: 100%;
}

.waya-official .rename-account-modal .rename-account-modal-input-group > * {
  display: block;
}

.waya-official .rename-account-modal .rename-account-modal-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
  margin-bottom: 0.25rem;
  text-align: left;
}

.waya-official .rename-account-modal .rename-account-modal-input {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
}

.waya-official .rename-account-modal .rename-account-modal-input-for-file-upload {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}

.waya-official .rename-account-modal .rename-account-modal-input-for-file-upload-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  background: #ececec;
  border-left: 1px solid #c8c8c8;
  margin-left: auto;
  padding: 0.6rem 1.2rem;
}

.waya-official .rename-account-modal .rename-account-modal-input.rename-account-modal-input-active {
  border-color: #FF6700;
  color: #FF6700;
}

.waya-official .rename-account-modal .rename-account-modal-radio-input-group-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.waya-official .rename-account-modal .rename-account-modal-radio-input-group {
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
}

.waya-official
  .rename-account-modal
  .rename-account-modal-radio-input-group.radio-input-group__with-border {
  border-radius: 5px;
  border: 1px solid #c8c8c8;
}

.waya-official .rename-account-modal .rename-account-modal-radio-circle {
  width: 12px;
  height: 12px;
  outline: 3px solid #c8c8c8;
  outline-offset: 4px;
  border-radius: 50%;
  padding: 0.2rem;
  background-color: transparent;
}

.waya-official
  .rename-account-modal
  .rename-account-modal-radio-circle.rename-account-modal-radio-circle-active {
  background-color: #27ae60;
  outline-color: #27ae60;
}

.waya-official .rename-account-modal .rename-account-modal-radio-text {
  margin-left: 1rem;
  color: #c8c8c8;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.waya-official
  .rename-account-modal
  .rename-account-modal-radio-text.more-settings-radio-text-active {
  color: #27ae60;
}

.waya-official .rename-account-modal .rename-account-modal__action-btns-container {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.waya-official .rename-account-modal .rename-account-modal__btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  background-color: #d6d6d6;
  border-radius: 3px;
  padding: 0.6rem 1.8rem;
  margin-top: 3rem;
}

.waya-official .rename-account-modal .rename-account-modal__btn.rename-account-modal__orange-btn {
  color: #ffffff;
  background: #ff4b01;
}
