.waya-official .more-settings-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waya-official .more-settings-modal .more-settings-main-content {
  background-color: #ffffff;
  border-radius: 8px;
  width: 75vw;
  max-width: 1400px;
  min-height: 80vh;
  overflow: hidden;
  border-radius: 12px;
  padding: 1.5rem;
}

.waya-official .more-settings-cancel-btn {
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
}

.waya-official .more-settings-modal .more-settings-nav-container {
  display: flex;
}

.waya-official .more-settings-modal .more-settings-nav-item {
  flex-grow: 1;
}

.waya-official .more-settings-modal .more-settings-nav-item button {
  display: block;
  width: 100%;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #a8a8a8;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #a8a8a8;
  white-space: nowrap;
}

.waya-official .more-settings-modal .more-settings-nav-item.more-settings-nav-item-active button {
  border-color: #ff4b01;
  color: #ff4b01;
}

.waya-official
  .more-settings-modal
  .more-settings-nav-item:not(:first-child):not(:last-child)
  button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.waya-official .more-settings-modal .more-settings-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waya-official .more-settings-modal .more-settings-body .more-settings-body-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.waya-official .more-settings-modal .more-settings-input-group {
  margin-top: 2rem;
  width: min(70%, 469px);
}

.waya-official .more-settings-modal .more-settings-input-group.more-settings__full-input-group {
  width: 100%;
}

.waya-official .more-settings-modal .more-settings-input-group > * {
  display: block;
}

.waya-official .more-settings-modal .more-settings-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
  margin-bottom: 0.25rem;
}

.waya-official .more-settings-modal .more-settings-input {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
}

.waya-official .more-settings-modal .more-settings-radio-input-group-container {
  width: min(70%, 469px);
  display: flex;
  justify-content: space-evenly;
  margin-top: 3rem;
}

.waya-official .more-settings-modal .more-settings-radio-input-group {
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem;
}

.waya-official
  .more-settings-modal
  .more-settings-radio-input-group.radio-input-group__with-border {
  border-radius: 5px;
  border: 1px solid #c8c8c8;
}

.waya-official
  .more-settings-modal
  .more-settings-radio-input-group.radio-input-group__with-border.radio-input-group__with-border-active {
  border-color: #27ae60;
}

.waya-official .more-settings-modal .more-settings-radio-circle {
  width: 12px;
  height: 12px;
  outline: 3px solid #c8c8c8;
  outline-offset: 4px;
  border-radius: 50%;
  padding: 0.2rem;
  background-color: transparent;
}

.waya-official .more-settings-modal .more-settings-radio-circle.more-settings-radio-circle-active {
  background-color: #27ae60;
  outline-color: #27ae60;
}

.waya-official .more-settings-modal .more-settings-radio-text {
  margin-left: 1rem;
  color: #c8c8c8;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.waya-official .more-settings-modal .more-settings-radio-text.more-settings-radio-text-active {
  color: #27ae60;
}

.waya-official .more-settings-modal .more-settings-create-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  background-color: #d6d6d6;
  border-radius: 3px;
  padding: 0.6rem 1.8rem;
  margin-top: 3rem;
}

.waya-official .more-settings-modal .more-settings-create-btn.more-settings__orange-btn {
  color: #ffffff;
  background: #ff4b01;
}

/* LinkedCards */
.waya-official .more-settings-body.linked-cards__cards-general-container {
  margin-top: 2rem;
  padding: 0 8%;
  overflow: auto;
}

.waya-official .more-settings-body .linked-cards__cards-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.waya-official .more-settings-body .linked-cards__cards-container > * + * {
  margin-left: 3%;
}

.waya-official .more-settings-body .linked-cards__add-card-container {
  background: #ffffff;
  border: 1px dashed #FF6700;
  border-radius: 4px;
  margin-top: 2rem;
  display: block;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FF6700;
}

.waya-official .more-settings-body .linked-cards__add-card {
  margin-top: 0.2rem;
}

/* LinkedCardsCard */
.waya-official .more-settings-body .linked-cards-card {
  flex: 1 0 40%;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  height: 204px;
}

.waya-official .more-settings-body .gradient-orange-card {
  background: linear-gradient(255.74deg, #ffcfbb -84.31%, #ff9467 260.97%);
}

.waya-official .more-settings-body .white-card {
  background: #ffffff;
}

.waya-official .more-settings-body .linked-cards-card__details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.waya-official .more-settings-body .linked-cards-card__label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.linked-cards-card__label__light-dark {
  color: #fafafa;
}

.linked-cards-card__label__mid-dark {
  color: #666666;
}

.waya-official .more-settings-body .linked-cards-card__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}

.waya-official .more-settings-body .linked-cards-card__more-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.waya-official .more-settings-body .linked-cards-card__dropdown {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-90%);
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(100, 100, 100, 0.25);
  border-radius: 5px;
  overflow: hidden;
}
.waya-official .more-settings-body .linked-cards-card__dropitem button {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
  display: block;
  text-align: left;
  padding: 0.6rem 0.8rem;
  white-space: nowrap;
}

.waya-official .more-settings-body .linked-cards-card__dropitem:hover,
.waya-official .more-settings-body .linked-cards-card__dropitem:focus {
  background-color: var(--extra-lighter-dark);
}

/* ResetWayaPassword */

/* UpdateWayaOfficialDetails */
.waya-official .more-settings-body .input-group-category-container {
  padding: 0 10%;
  width: 100%;
}

.waya-official .more-settings-body .input-group-category > * {
  flex-grow: 1;
}

.waya-official .more-settings-body .input-group-category {
  display: flex;
  justify-content: space-between;
}

.waya-official .more-settings-body .input-group-category > * + * {
  margin-left: 10%;
}

/* Error Msg */
.waya-official .more-settings-body .more-settings__error-msg {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ff4b01;
  margin-top: 2rem;
}
