.request-logs {
  --light-orange: #e7472c29;
  --orange: #ff6634;
  --dark-orange: #ff4b01;
}

.request-logs__container {
  background-color: #f5f5f5;
  font-family: "Inter", sans-serif;
  color: #333333;
  padding: 0.5rem 1rem;
}

.request-logs hidden {
  display: none;
}

.request-logs * {
  box-sizing: border-box;
}

.request-logs p,
.request-logs h5 {
  margin: 0;
}

.request-logs ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.request-logs button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.request-logs input {
  border: none;
  outline: none;
}

/* Action Sections */
.request-logs .request-logs__actions-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0.6rem 1rem;
  background-color: #fff;
}

.request-logs .request-logs__actions-container > * + * {
  margin-left: 1rem;
}

.request-logs .request-logs__filter-btn-container {
  position: relative;
}

.request-logs .request-logs__filter-btn {
  border: 1px solid var(--orange);
  border-radius: 6px;
  padding: 0.5rem;
}

/* FilterDropdown */
.request-logs .request-logs__filter-dropdown {
  position: absolute;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 1.2rem;
  background-color: #fff;
  top: 100%;
  left: 0;
  min-width: 300px;
}

.request-logs .request-logs__filter-dropdown__input-group {
  width: 100%;
}

.request-logs .request-logs__filter-dropdown__input-group > * {
  display: block;
}

.request-logs .request-logs__filter-dropdown__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
  margin-bottom: 0.25rem;
  text-align: left;
  white-space: nowrap;
}

.request-logs .request-logs__filter-dropdown__select-dropdown-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
}

.request-logs
  .request-logs__filter-dropdown__select-dropdown-container.request-logs__filter-dropdown__select-dropdown-container-active {
  border-color: #FF6700;
  color: #FF6700;
}

.request-logs .request-logs__filter-dropdown__btns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  position: relative;
}

.request-logs .request-logs__filter-dropdown__btns-container button {
  color: #fff;
  background-color: #FF6700;
  padding: 0.4rem 0.8rem;
  border-radius: 3px;
}

/* SelectDropdown */

.request-logs .request-logs__filter-dropdown__select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 400;
  border-radius: 3px;
}

.request-logs .request-logs__filter-dropdown__select-dropitem button {
  display: block;
  padding: 0.6rem 0.8rem;
  width: 100%;
  border-bottom: 1px solid #e8e7e7;
  text-align: left;
}

.request-logs .request-logs__filter-dropdown__select-dropitem button:hover,
.request-logs .request-logs__filter-dropdown__select-dropitem button:focus {
  background-color: #e8e7e7;
}

.request-logs .request-logs__search-container {
  position: relative;
}

.request-logs .request-logs__search-btn {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.request-logs .request-logs__search-input {
  display: block;
  border: 1px solid var(--orange);
  border-radius: 6px;
  padding: 0.6rem;
  padding-left: 2.2rem;
  font-size: 16px;
  color: #333333;
}

.request-logs .request-logs__search-input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #978c8a;
}
/* End of SelectDropdown */

.request-logs .request-logs__export-btn {
  padding: 0.4rem 1.5rem;
  background-color: var(--dark-orange);
  color: #fff;
  border-radius: 6px;
  margin-left: auto;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.3px;
  height: fit-content;
}

.request-logs .request-logs__statistics {
  display: flex;
  margin-top: 2rem;
}

.request-logs .request-logs__statistics.request-logs__space-around {
  justify-content: space-around;
}

.request-logs .request-logs__statistics.request-logs__flex-start {
  justify-content: flex-start;
}

.request-logs .request-logs__statistic-container {
  background: #fbfbfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 30%;
  max-width: 400px;
  padding: 1.4rem;
}

.request-logs .request-logs__statistic-container:not(:first-child) {
  margin-left: 2rem;
}

.request-logs .request-logs__statistic-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.request-logs .request-logs__statistic-total {
  margin-top: 2.5rem;
  font-weight: 700;
  font-size: 39px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #333333;
}

/* Table */

.request-logs .request-logs__table-container {
  overflow: auto;
  border-radius: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-size: 12px;
  line-height: 140%;
  color: #333333;
  margin-top: 2rem;
}

.request-logs .request-logs__table-container::-webkit-scrollbar {
  display: none;
}

.request-logs .request-logs__table {
  background-color: #fff;
  min-width: 100%;
  text-align: center;
  text-align: left;
}

.request-logs .request-logs__table .request-logs__text-right {
  text-align: right;
}

.request-logs .request-logs__table .request-logs__text-center {
  text-align: center;
}

.request-logs .request-logs__table__head {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  color: var(--orange);
  background-color: var(--light-orange);
}

.request-logs .request-logs__table__head > th {
  padding: 1.2rem;
}

.word-wrap {
  white-space: wrap;
}

.request-logs .request-logs__table__row {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

.request-logs .request-logs__table__row:not(:last-child) td {
  border-bottom: 1px solid #e8e7e7;
}

.request-logs .request-logs__table__row td {
  padding: 1rem;
}

.request-logs .request-logs__table__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange);
  background-color: var(--light-orange);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  padding: 1rem;
}

.request-logs .request-logs__table__footer > * + * {
  margin-left: 4rem;
}

.request-logs .request-logs__table__rows-per-page-container {
  display: flex;
  position: relative;
  align-items: center;
}

.request-logs .request-logs__table__rows-per-page-container > * + * {
  margin-left: 0.5rem;
}

.request-logs .request-logs__rows-per-page-dropdown {
  bottom: 0;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  background-color: var(--light);
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.request-logs .request-logs__rows-per-page-dropitem {
  margin: 0;
}

.request-logs .request-logs__rows-per-page-dropitem button {
  padding: 0.6rem 1.5rem 0.6rem 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: #333333;
  text-align: left;
}

.request-logs .request-logs__rows-per-page-dropitem button:hover,
.request-logs .request-logs__rows-per-page-dropitem button:focus {
  background-color: #f5f5f5;
}

.request-logs .request-logs__rows-per-page-dropitem:not(:last-child) {
  border-bottom: 1px solid #e8e7e7;
}

.request-logs .request-logs__page-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.request-logs .request-logs__page-nav-container > * + * {
  margin-left: 3rem;
}

/* Switch Btn */
.request-logs .request-logs__switch-btn-container {
  position: relative;
  background: #eee7e6;
  border-radius: 50px;
  width: 52px;
  height: 26px;
}

.request-logs .request-logs__switch-btn-container button {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.55);
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.request-logs .request-logs__switch-btn-container.request-logs__switch-btn-container-active {
  background: #ff4b01;
}

.request-logs .request-logs__switch-btn-container.request-logs__switch-btn-container-active button {
  left: auto;
  right: 0;
}

/* Events */
.request-logs .request-logs__filter-input-group {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.request-logs .request-logs__filter-input-group .request-logs__filter-dropdown__input-group {
  width: 200px;
}

.request-logs
  .request-logs__filter-input-group
  .request-logs__filter-dropdown__input-group:not(:first-child) {
  margin-left: 2rem;
}

.request-logs .request-logs__filter-input-group .request-logs__filter-dropdown__label {
  color: #9e9e9e;
  text-transform: uppercase;
}

.request-logs .request-logs__filter-input-group .request-logs__filter-dropdown__input-date {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
}

.request-logs__events-table__id-cell div {
  color: rgba(51, 51, 51, 0.8);
  display: flex;
}

.request-logs__events-table__id-cell div > * + * {
  margin-left: 1rem;
}

.request-logs__events-table__date-cell {
  white-space: nowrap;
}

.request-logs__events-table__date-cell > * + * {
  margin-top: 0.25rem;
}

.request-logs__events-table__status-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.request-logs__events-table__approved-status-cell {
  color: #05b862;
}
.request-logs__events-table__rejected-status-cell {
  color: #f3ce00;
}
.request-logs__events-table__pending-status-cell {
  color: #e7472c;
}
.request-logs__events-table__reviewed-status-cell {
  color: #333333;
}

.request-logs__events-table__action-cell {
  text-align: center;
}
