.waya-official .loading-status-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waya-official .loading-status-modal h3,
.waya-official .loading-status-modal p {
  margin: 0;
}

.waya-official .loading-status-modal .loading-status-modal__main-content {
  background-color: #ffffff;
  border-radius: 8px;
  width: min(80vw, 606px);
  overflow: hidden;
  border-radius: 12px;
  text-align: center;
}

.waya-official .loading-status-modal .loading-status-modal__title-container {
  background: #f1f7fa;
  border: 1px solid #e0e0e0;
  padding: 1.5rem;
}

.waya-official .loading-status-modal .loading-status-modal__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.waya-official .loading-status-modal .loading-status-modal__body {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waya-official .loading-status-modal .loading-status-modal__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #828282;
  margin-top: 3rem;
}
