.fraud-management {
  --light-orange: #e7472c29;
  --orange: #ff6634;
  --dark-orange: #ff4b01;
}

.fraud-management__container {
  background-color: #f5f5f5;
  font-family: "Inter", sans-serif;
  color: #333333;
  padding: 0.5rem 1rem;
}

.fraud-management hidden {
  display: none;
}

.fraud-management * {
  box-sizing: border-box;
}

.fraud-management p,
.fraud-management h5 {
  margin: 0;
}

.fraud-management ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.fraud-management button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.fraud-management input {
  border: none;
  outline: none;
}

.fraud-management .fraud-management__nav-btns {
  display: flex;
}

.fraud-management .fraud-management__nav-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
  padding: 0.8rem 1.2rem;
  border-radius: 2rem;
}

/* .fraud-management .fraud-management__nav-btn:not(:first-child) {
  margin-left: 1rem;
} */

.fraud-management .fraud-management__nav-btn.fraud-management__nav-btn-active {
  color: #ffffff;
  background: var(--dark-orange);
}

.fraud-management .fraud-management__filtering-container {
  display: flex;
  margin-top: 1rem;
}

.fraud-management .fraud-management__filtering-container > * + * {
  margin-left: 1rem;
}

.fraud-management .fraud-management__search-container {
  position: relative;
}

.fraud-management .fraud-management__search-btn {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.fraud-management .fraud-management__search-input {
  display: block;
  border: 1px solid var(--orange);
  border-radius: 6px;
  padding: 0.6rem;
  padding-left: 2.2rem;
  font-size: 16px;
  color: #333333;
}

.fraud-management .fraud-management__search-input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #978c8a;
}

.fraud-management .fraud-management__filter-btn-container {
  position: relative;
}

.fraud-management .fraud-management__filter-btn {
  border: 1px solid var(--orange);
  border-radius: 6px;
  padding: 0.5rem;
}

.fraud-management .fraud-management__main-content {
}

.fraud-management .fraud-management__statistics {
  display: flex;
  margin-top: 2rem;
}

.fraud-management .fraud-management__statistics.fraud-management__space-around {
  justify-content: space-around;
}

.fraud-management .fraud-management__statistics.fraud-management__flex-start {
  justify-content: flex-start;
}

.fraud-management .fraud-management__statistic-container {
  background: #fbfbfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 30%;
  max-width: 400px;
  padding: 1.4rem;
}

.fraud-management .fraud-management__statistic-container:not(:first-child) {
  margin-left: 2rem;
}

.fraud-management .fraud-management__dark-statistic-container {
  color: #1c1c1c;
}
.fraud-management .fraud-management__green-statistic-container {
  color: #27ae60;
}
.fraud-management .fraud-management__red-statistic-container {
  color: #ff0000;
}

.fraud-management .fraud-management__statistic-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.fraud-management .fraud-management__statistic-total {
  margin-top: 2.5rem;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
}

/* Table */

.fraud-management .fraud-management__table-container {
  overflow: auto;
  border-radius: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-size: 12px;
  line-height: 140%;
  color: #000000;
  margin-top: 2rem;
}

.fraud-management .fraud-management__table-container::-webkit-scrollbar {
  display: none;
}

/* .fraud-management .fraud-management__table-container > div {
  display: inline-block;
  min-width: 100%;
} */

.fraud-management .fraud-management__table {
  background-color: #fff;
  min-width: 100%;
  text-align: center;
  text-align: left;
}

.fraud-management .fraud-management__table .fraud-management__text-right {
  text-align: right;
}

.fraud-management .fraud-management__table .fraud-management__text-center {
  text-align: center;
}

.fraud-management .fraud-management__table__head {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  color: var(--orange);
  background-color: var(--light-orange);
}

.fraud-management .fraud-management__table__head > th {
  padding: 1.2rem;
}

.fraud-management .fraud-management__table__head .fraud-management__table__rule-cell,
.fraud-management .fraud-management__table__row .fraud-management__table__rule-cell {
  width: 45%;
}
.fraud-management .fraud-management__table__head .fraud-management__table__action-cell,
.fraud-management .fraud-management__table__row .fraud-management__table__action-cell {
  width: 45%;
}
.fraud-management .fraud-management__table__head .fraud-management__table__switch-cell,
.fraud-management .fraud-management__table__row .fraud-management__table__switch-cell {
  width: 10%;
}

.word-wrap {
  white-space: wrap;
}

.fraud-management .fraud-management__table__row {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

.fraud-management .fraud-management__table__row:not(:last-child) td {
  border-bottom: 1px solid #e8e7e7;
}

.fraud-management .fraud-management__table__row td {
  padding: 1rem;
}

.fraud-management .fraud-management__table__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange);
  background-color: var(--light-orange);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  padding: 1rem;
}

.fraud-management .fraud-management__table__footer > * + * {
  margin-left: 4rem;
}

.fraud-management .fraud-management__table__rows-per-page-container {
  display: flex;
  position: relative;
  align-items: center;
}

.fraud-management .fraud-management__table__rows-per-page-container > * + * {
  margin-left: 0.5rem;
}

.fraud-management .fraud-management__rows-per-page-dropdown {
  bottom: 0;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  background-color: var(--light);
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.fraud-management .fraud-management__rows-per-page-dropitem {
  margin: 0;
}

.fraud-management .fraud-management__rows-per-page-dropitem button {
  padding: 0.6rem 1.5rem 0.6rem 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: #333333;
  text-align: left;
}

.fraud-management .fraud-management__rows-per-page-dropitem button:hover,
.fraud-management .fraud-management__rows-per-page-dropitem button:focus {
  background-color: #f5f5f5;
}

.fraud-management .fraud-management__rows-per-page-dropitem:not(:last-child) {
  border-bottom: 1px solid #e8e7e7;
}

.fraud-management .fraud-management__page-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fraud-management .fraud-management__page-nav-container > * + * {
  margin-left: 3rem;
}

/* Switch Btn */
.fraud-management .fraud-management__switch-btn-container {
  position: relative;
  background: #eee7e6;
  border-radius: 50px;
  width: 52px;
  height: 26px;
}

.fraud-management .fraud-management__switch-btn-container button {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.55);
  width: 23px;
  height: 23px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.fraud-management
  .fraud-management__switch-btn-container.fraud-management__switch-btn-container-active {
  background: #ff4b01;
}

.fraud-management
  .fraud-management__switch-btn-container.fraud-management__switch-btn-container-active
  button {
  left: auto;
  right: 0;
}

/* FilterDropdown */
.fraud-management .fraud-management__filter-dropdown {
  position: absolute;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 1.2rem;
  background-color: #fff;
  top: 100%;
  right: 0;
  min-width: 300px;
}

.fraud-management .fraud-management__filter-dropdown__input-group {
  width: 100%;
}

.fraud-management .fraud-management__filter-dropdown__input-group > * {
  display: block;
}

.fraud-management .fraud-management__filter-dropdown__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #333333;
  margin-bottom: 0.25rem;
  text-align: left;
  white-space: nowrap;
}

.fraud-management .fraud-management__filter-dropdown__select-dropdown-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
}

.fraud-management
  .fraud-management__filter-dropdown__select-dropdown-container.fraud-management__filter-dropdown__select-dropdown-container-active {
  border-color: #FF6700;
  color: #FF6700;
}

.fraud-management .fraud-management__filter-dropdown__btns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  position: relative;
}

.fraud-management .fraud-management__filter-dropdown__btns-container button {
  color: #fff;
  background-color: #FF6700;
  padding: 0.4rem 0.8rem;
  border-radius: 3px;
}

/* SelectDropdown */

.fraud-management .fraud-transactions__filter-dropdown__select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 400;
  border-radius: 3px;
}

.fraud-management .fraud-transactions__filter-dropdown__select-dropitem button {
  display: block;
  padding: 0.6rem 0.8rem;
  width: 100%;
  border-bottom: 1px solid #e8e7e7;
  text-align: left;
}

.fraud-management .fraud-transactions__filter-dropdown__select-dropitem button:hover,
.fraud-management .fraud-transactions__filter-dropdown__select-dropitem button:focus {
  background-color: #e8e7e7;
}

/* Events */
.fraud-management .fraud-management__filter-input-group {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.fraud-management
  .fraud-management__filter-input-group
  .fraud-management__filter-dropdown__input-group {
  width: 200px;
}

.fraud-management
  .fraud-management__filter-input-group
  .fraud-management__filter-dropdown__input-group:not(:first-child) {
  margin-left: 2rem;
}

.fraud-management .fraud-management__filter-input-group .fraud-management__filter-dropdown__label {
  color: #9e9e9e;
  text-transform: uppercase;
}

.fraud-management
  .fraud-management__filter-input-group
  .fraud-management__filter-dropdown__input-date {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
  background-color: inherit;
}

.fraud-management__events-table__name-cell {
}

.fraud-management__events-table__ip-cell {
  white-space: nowrap;
}

.fraud-management__events-table__scenario-cell {
  width: 30%;
  vertical-align: top;
}

.fraud-management__events-table__action-performed-cell {
  width: 30%;
  vertical-align: top;
}

.fraud-management__events-table__date-cell {
  white-space: nowrap;
}

.fraud-management__events-table__date-cell > * + * {
  margin-top: 0.25rem;
}
