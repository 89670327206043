.referral-setting-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-setting-modal .referral-setting-main-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  width: min(70vw, 596px);
}

.referral-setting-modal .referral-setting-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.referral-setting-modal .referral-setting-title-container > * {
  display: block;
}

.referral-setting-modal .referral-setting-title-container h3 {
  margin: 0 auto;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}

.referral-setting-modal .referral-setting-inputs-container > * + * {
  margin-top: 2rem;
}

.referral-setting-modal .referral-setting-inputs-container {
  padding: 0 2rem;
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: 4rem;
}

.referral-setting-modal .referral-setting-input-group > * {
  display: block;
}

.referral-setting-modal .referral-setting-input-group label {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 6px;
}

.referral-setting-modal .referral-setting-input-content {
  position: relative;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 1rem;
}

.referral-setting-modal .referral-setting-transaction-text {
  flex: 1 0 auto;
}

.referral-setting-modal .referral-setting-transaction-btn {
  flex-shrink: 0;
}

.referral-setting-modal .referral-setting-transaction-btn svg {
  transform: translateY(25%);
}

ul.referral-setting-transaction-dropdown {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 300;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #fbfbfb;
}

ul.referral-setting-transaction-dropdown li + li {
  border: 1px solid #e8e7e7;
}

ul.referral-setting-transaction-dropdown li button {
  display: block;
  width: 100%;
  padding: 0.8rem 1.2rem;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

ul.referral-setting-transaction-dropdown li button:hover,
ul.referral-setting-transaction-dropdown li button:focus {
  background-color: #e8e7e7;
}

.referral-setting-modal input.referral-setting-input-content {
  outline: none;
  width: 100%;
}

.referral-setting-modal .referral-setting-save-btn-container button {
  display: block;
  margin: 0 auto;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  background: #ff4b01;
  border-radius: 0.25rem;
  padding: 0.6rem 1.2rem;
  margin-top: 3rem;
}
