.manage-admin-users {
  --light-orange: #e7472c29;
  --orange: #ff6634;
  --dark-orange: #ff4b01;
  background: #f5f5f5;
  padding: 16px;
}

.manage-admin-users * {
  box-sizing: border-box;
}

.manage-admin-users p {
  margin: 0;
}

.manage-admin-users ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.manage-admin-users button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.manage-admin-users input {
  border: none;
  outline: none;
}

.manage-admin-users .manage-admin-users__top-actions {
  display: flex;
  justify-content: end;
}

/* Search Btn Container */
.manage-admin-users .manage-admin-users__search-container {
  position: relative;
}

.manage-admin-users .manage-admin-users__search-btn {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.manage-admin-users .manage-admin-users__search-input {
  display: block;
  border: 1px solid var(--orange);
  border-radius: 6px;
  padding: 0.6rem;
  padding-left: 2.2rem;
  font-size: 16px;
  color: #333333;
}

.manage-admin-users .manage-admin-users__search-input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #978c8a;
}

/* Action Btns */
.manage-admin-users .manage-admin-users__top-actions-btns {
  display: flex;
  align-items: center;
}

.manage-admin-users .manage-admin-users__top-actions-btns > * + * {
  margin-left: 1.2rem;
}

.manage-admin-users .manage-admin-users__top-actions__settings-btn,
.manage-admin-users .manage-admin-users__top-actions__create-btn {
  display: flex;
  border-radius: 3px;
  align-items: center;
  padding: 0.6rem;
}

.manage-admin-users .manage-admin-users__top-actions__settings-btn p,
.manage-admin-users .manage-admin-users__top-actions__create-btn p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 0.6rem;
}

.manage-admin-users .manage-admin-users__top-actions__settings-btn {
  background: #fff;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.manage-admin-users .manage-admin-users__top-actions__settings-btn p {
  color: #333333;
}

.manage-admin-users .manage-admin-users__top-actions__create-btn {
  background-color: #ff4b01;
}

.manage-admin-users .manage-admin-users__top-actions__create-btn p {
  color: #ffffff;
}

/* Statistics */
.manage-admin-users .manage-admin-users__statistics-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 1rem;
  overflow: auto;
  padding: 1rem 0.5rem;
}

.manage-admin-users .manage-admin-users__statistics-container > * + * {
  margin-left: 0.8rem;
}

.manage-admin-users .manage-admin-users__statistics {
  background: #fbfbfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-width: min(200px, 30vw);
  padding: 1.4rem;
}

.manage-admin-users .manage-admin-users__statistics__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  white-space: nowrap;
}

.manage-admin-users .manage-admin-users__statistics__total {
  margin-top: 2.5rem;
  font-weight: 700;
  font-size: 39px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #333333;
}

/* Filter */
.manage-admin-users .manage-admin-users__filter-input-group {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.manage-admin-users
  .manage-admin-users__filter-input-group
  .manage-admin-users__filter-dropdown__input-group {
  width: 200px;
}

.manage-admin-users
  .manage-admin-users__filter-input-group
  .manage-admin-users__filter-dropdown__input-group:not(:first-child) {
  margin-left: 2rem;
}

.manage-admin-users
  .manage-admin-users__filter-input-group
  .manage-admin-users__filter-dropdown__label {
  color: #9e9e9e;
  text-transform: uppercase;
  font-size: 12px;
}

.manage-admin-users
  .manage-admin-users__filter-input-group
  .manage-admin-users__filter-dropdown__input-date {
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
}

/* SelectDropdown */

.manage-admin-users .manage-admin-users__filter-dropdown__select-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 400;
  border-radius: 3px;
}

.manage-admin-users
  .manage-admin-users__filter-dropdown__select-dropdown-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 100%;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
}

.manage-admin-users
  .manage-admin-users__filter-dropdown__select-dropitem
  button {
  display: block;
  padding: 0.8rem;
  width: 100%;
  border-bottom: 1px solid #e8e7e7;
  text-align: left;
}

.manage-admin-users
  .manage-admin-users__filter-dropdown__select-dropitem
  button:hover,
.manage-admin-users
  .manage-admin-users__filter-dropdown__select-dropitem
  button:focus {
  background-color: #e8e7e7;
}

/* Table */

.manage-admin-users .manage-admin-users__table-container {
  overflow: auto;
  border-radius: 0.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-top: 2rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.manage-admin-users .manage-admin-users__table-container::-webkit-scrollbar {
  display: none;
}

.manage-admin-users .manage-admin-users__table {
  background-color: #fff;
  min-width: 100%;
  text-align: center;
  text-align: left;
}

.manage-admin-users .manage-admin-users__table .manage-admin-users__text-right {
  text-align: right;
}

.manage-admin-users
  .manage-admin-users__table
  .manage-admin-users__text-center {
  text-align: center;
}

.manage-admin-users .manage-admin-users__table__head {
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  color: var(--orange);
  background-color: var(--light-orange);
}

.manage-admin-users .manage-admin-users__table__head > th {
  padding: 1.2rem;
}

.word-wrap {
  white-space: wrap;
}

.manage-admin-users .manage-admin-users__table__row {
  text-align: left;
}

.manage-admin-users .manage-admin-users__table__row:not(:last-child) td {
  border-bottom: 1px solid #e8e7e7;
}

.manage-admin-users .manage-admin-users__table__row td {
  padding: 1rem;
}

.manage-admin-users .manage-admin-users__table__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange);
  background-color: var(--light-orange);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  padding: 1rem;
}

.manage-admin-users .manage-admin-users__table__footer > * + * {
  margin-left: 4rem;
}

.manage-admin-users .manage-admin-users__table__rows-per-page-container {
  display: flex;
  position: relative;
  align-items: center;
}

.manage-admin-users
  .manage-admin-users__table__rows-per-page-container
  > *
  + * {
  margin-left: 0.5rem;
}

.manage-admin-users .manage-admin-users__rows-per-page-dropdown {
  bottom: 0;
  right: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: var(--shadow);
  border-radius: 0.5rem;
  background-color: var(--light);
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.manage-admin-users .manage-admin-users__rows-per-page-dropitem {
  margin: 0;
}

.manage-admin-users .manage-admin-users__rows-per-page-dropitem button {
  padding: 0.6rem 1.5rem 0.6rem 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: #333333;
  text-align: left;
}

.manage-admin-users .manage-admin-users__rows-per-page-dropitem button:hover,
.manage-admin-users .manage-admin-users__rows-per-page-dropitem button:focus {
  background-color: #f5f5f5;
}

.manage-admin-users
  .manage-admin-users__rows-per-page-dropitem:not(:last-child) {
  border-bottom: 1px solid #e8e7e7;
}

.manage-admin-users .manage-admin-users__page-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-admin-users .manage-admin-users__page-nav-container > * + * {
  margin-left: 3rem;
}

/* Table Cells */

.manage-admin-users .manage-admin-users__events-table__invite-sent-cell div {
  color: #FF6700;
}

.manage-admin-users .manage-admin-users__events-table__date-cell div {
  white-space: nowrap;
}

.manage-admin-users .manage-admin-users__events-table__date-cell div > * + * {
  margin-top: 0.25rem;
}

.manage-admin-users .manage-admin-users__events-table__action-cell div {
  display: flex;
  align-items: center;
}

.manage-admin-users .manage-admin-users__events-table__action-cell div > * + * {
  margin-left: 0.4rem;
}

.manage-admin-users .manage-admin-users__change-role-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6a6a6a;
  background: #ffffff;
  border: 1px solid #d0cfcf;
  border-radius: 3px;
  padding: 0.3rem 0.4rem;
}

.manage-admin-users .manage-admin-users__delete-admin-btn {
}
