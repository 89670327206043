.assign-referral-code-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-referral-code-modal .assign-referral-code-main-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  width: min(70vw, 596px);
}

.assign-referral-code-modal .assign-referral-code-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assign-referral-code-modal .assign-referral-code-title-container > * {
  display: block;
}

.assign-referral-code-modal .assign-referral-code-title-container h3 {
  margin: 0 auto;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}

.assign-referral-code-modal .assign-referral-code-inputs-container {
  padding: 0 2rem;
  color: #333333;
  margin-top: 4rem;
}

.assign-referral-code-modal .assign-referral-code-inputs-container > * + * {
  margin-top: 1.5rem;
}

.assign-referral-code-modal .assign-referral-code-input-group > * {
  display: block;
}

.assign-referral-code-modal .assign-referral-code-input-group label {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  margin-bottom: 6px;
}
.assign-referral-code-modal .assign-referral-code-input-group input {
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 0.6rem;
  outline: none;
  width: 100%;
}

.assign-referral-code-modal .assign-referral-code-save-btn-container button {
  display: block;
  margin: 0 auto;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
  background: #ff4b01;
  border-radius: 0.25rem;
  padding: 0.6rem 1.2rem;
  margin-top: 3rem;
}
