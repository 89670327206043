.waya-official .rename-account-modal .rename-account-modal-dropitem {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #333333;
}

.waya-official .rename-account-modal .rename-account-modal-dropitem:not(:last-child) {
  border: 1px solid #f3f3f3;
}

.waya-official .rename-account-modal .rename-account-modal-checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid #c8c8c8;
  border-radius: 3px;
  background-color: transparent;
}

.waya-official .rename-account-modal .rename-account-modal-checkbox-active {
  background-color: blue;
  border-color: blue;
}
