.waya-official .create-new-account-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 200;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.waya-official .create-new-account-modal .create-new-account__main-content {
  background-color: #ffffff;
  border-radius: 8px;
  width: min(75vw, 604px);
  overflow: hidden;
  border-radius: 9px;
  padding: 1.5rem;
}

.waya-official .create-new-account-modal .create-new-account__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12%;
  margin-bottom: 3rem;
}

.waya-official .create-new-account-modal .create-new-account__cancel-btn {
  display: block;
  margin-left: auto;
  margin-bottom: 1rem;
}

.waya-official .create-new-account-modal .create-new-account__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  margin-bottom: 3rem;
}

.waya-official .create-new-account-modal .create-new-account__text-input-group {
  width: 100%;
}
.waya-official .create-new-account-modal .create-new-account__text-input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #333333;
}
.waya-official .create-new-account-modal .create-new-account__text-input,
.waya-official .create-new-account-modal .create-new-account__text-dropdown-input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  padding: 0.5rem;
}

.waya-official .create-new-account-modal .create-new-account__text-input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #c4c4c4;
}

.waya-official .create-new-account-modal .create-new-account__text-dropdown-input {
  position: relative;
}

.waya-official .create-new-account-modal .create-new-account__drop-btn {
  display: block;
  margin-left: auto;
}

.waya-official .create-new-account-modal .create-new-account__dropdown {
  position: absolute;
}

.waya-official .create-new-account-modal .create-new-account__action-btn {
  background: #d6d6d6;
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  margin-top: 1.5rem;
  padding: 0.6rem 1.5rem;
}

.waya-official .create-new-account-modal .create-new-account__action-btn.primary-action-btn {
  color: #ffffff;
  background: #ff4b01;
}

/* OTP */
.waya-official .create-new-account-modal .create-new-account__otp-input-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.waya-official .create-new-account-modal .create-new-account__otp-input {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 22px;
  text-align: center;
  color: #333333;
  width: 36px;
  border-bottom: 3px solid #c4c4c4;
}

.waya-official .create-new-account-modal .create-new-account__otp-input:not(:first-child) {
  margin-left: 2rem;
}

.waya-official .create-new-account-modal .create-new-account__error-msg {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ff4b01;
  margin-top: 1rem;
}
